import dayjs from 'dayjs';
import React from 'react'
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear)

const getSongUrl = (date) => {
  switch (dayjs(date).week()) {
    case 49:
      return 'https://www.youtube.com/watch?v=71cS_fdtQqE';
    case 50:
      return 'https://lizvice.bandcamp.com/track/refugee-king';
    case 51:
      return 'https://youtu.be/cpGu359wRjE';
    case 52:
      return 'https://joshgarrels.bandcamp.com/track/may-you-find-a-light';
    default:
      return 'https://www.youtube.com/watch?v=71cS_fdtQqE';
  }
};

export default ({ audioUrl, date }) => (
  <div className="section-wrapper welcome">
    <img alt="Welcome to All Souls Advent" className="welcome-image" src="/welcome.png" />

    <div className="buttons">
      {audioUrl && (
        <a href={audioUrl} className="button" target="_blank">Play Audio Version</a>
      )}
      <a href={getSongUrl(date)} className="button" target="_blank">Play Song of the Week</a>
    </div>
  </div>
);
