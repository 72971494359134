import React from "react"

import Video from '../components/video';

// Fill this in when we have a URL
const callUrl = 'https://us02web.zoom.us/j/84717790674';
const videoUrl = 'https://player.vimeo.com/video/486673302';

export default () => {
  return (
    <section className="section intro">
      <div className="section-wrapper">
        <h1 className="event-title"><b>Wreath Making</b> with Alyssa Reeve</h1>

        {!videoUrl && (
          <a className="video-placeholder" href={callUrl}>
            <span>Zoom call begins at 7p PST.<br />Click within the frame to join.</span>
          </a>
        )}

        {videoUrl && (
          <Video videoEmbedUrl={videoUrl} />
        )}

        <div className="video-description">
          <p>Join for an evening of crafting beautiful, festive winter wreaths. Meanwhile, we’ll discuss what the Incarnation of Christ has to do with creation and re-creation, and how our creativity might serve as a form of prayer.</p>

          <div className="buttons activity">
            <a className="button" href="https://allsoulsseattle.s3-us-west-2.amazonaws.com/Advent+2020/Advent+2020+Embodied+Prayer.week+1.112320.pdf" target="_blank">Download Activity PDF</a>
            <a className="button" href="https://allsoulsseattle.s3-us-west-2.amazonaws.com/Advent+2020/Advent+at+All+Souls+-+ASK+reading+wk+1.pdf" target="_blank">Download Kids' Activity PDF</a>
          </div>

          <p className="instructions">
            What you’ll want from your Advent kit:
            <ul>
              <li>wreath form</li>
              <li>dried oranges + assorted greenery</li>
              <li>zip ties + floral wire</li>
              <li>for the kids: sticker wreaths</li>
            </ul>
          </p>
        </div>
      </div>
    </section>
  );
};
