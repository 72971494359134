import React from 'react'

export default ({ videoEmbedUrl }) => (
  <div className="video-wrapper">
    <iframe
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      frameBorder="0"
      src={videoEmbedUrl}
      title={videoEmbedUrl}
    />
  </div>
);
