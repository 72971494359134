import React from "react"

import Video from '../components/video';

// Fill this in when we have a URL
const callUrl = 'https://us02web.zoom.us/j/83600607120';
const videoUrl = 'https://player.vimeo.com/video/489265031';

export default () => {
  return (
    <section className="section intro">
      <div className="section-wrapper">
        <h1 className="event-title"><b>Movement</b> with Sydney Buckley</h1>

        {!videoUrl && (
          <a className="video-placeholder" href={callUrl}>
            <span>Zoom call begins at 7p PST.<br />Click within the frame to join.</span>
          </a>
        )}

        {videoUrl && (
          <Video videoEmbedUrl={videoUrl} />
        )}

        <div className="video-description">
          <p>When we move— be it dancing or simply bending to tie a shoe— we’re reminded that, all too frequently, our bodies can and do feel pain. In this activity, we’ll explore what Christ’s body— and the pain he felt with and for us— has to teach us about the prayer of lament. Then, we’ll shake it off with a time of movement and lyrical dance. All ages and experience levels are welcome!</p>

          <p><em>Note: this session highlights that engaging in movement can potentially cause pain. Over the course of the evening, please listen to your body. If you feel uncertain about your ability level, there’s no shame in taking a break!</em></p>

          <div className="buttons activity">
            <a className="button" href="https://allsoulsseattle.s3-us-west-2.amazonaws.com/Advent+2020/Advent+2020+Embodied+Prayer.week+2.112320.pdf" target="_blank">Download Activity PDF</a>
            <a className="button" href="https://allsoulsseattle.s3-us-west-2.amazonaws.com/Advent+2020/Advent+at+All+Souls+-+ASK+reading+wk+2.pdf" target="_blank">Download Kids' Activity PDF</a>
          </div>

          <p className="instructions">
            What you’ll want from your Advent kit:
            <ul>
              <li>glow sticks</li>
            </ul>
          </p>
        </div>
      </div>
    </section>
  );
};
