import React from 'react'

import KidsVideoLink from '../components/kidsVideoLink';
import Scripture from '../components/scripture';
import Sidebar from '../components/sidebar';
import Video from '../components/video';

export default ({
  audioUrl,
  kidsVideoEmbedUrl,
  kidsVideoEmbedUrl2,
  speaker,
  videoEmbedUrl,
  ...scriptureProps
}) => (
  <div className="section-wrapper reflection">
    <KidsVideoLink
      kidsVideoEmbedUrl={kidsVideoEmbedUrl}
      kidsVideoEmbedUrl2={kidsVideoEmbedUrl2}
    />

    <Sidebar audioUrl={audioUrl} iconName="reflection" title="Reflection" />

    <Video videoEmbedUrl={videoEmbedUrl} />

    <div className="hymn-details">
      <div>
        <h3 className="hymn-details--name">{speaker}</h3>
      </div>
    </div>

    <Scripture {...scriptureProps} />

    <hr />
  </div>
);
