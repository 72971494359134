import React from 'react'

import Scripture from '../components/scripture';
import Sidebar from '../components/sidebar';

export default (props) => (
  <div className="section-wrapper">
    <Sidebar
      iconName="scripture"
      title="Scripture Reading"
    />

    <Scripture {...props} />

    <hr />
  </div>
);
