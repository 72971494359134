import React from "react"

import Video from '../components/video';

// Fill this in when we have a URL
const call1Url = 'https://us02web.zoom.us/j/87874370748?pwd=TzhZZ3Z5cGxzd3htODhxODJBdW0xdz09';
const video1Url = '';

const call2Url = 'https://us02web.zoom.us/j/88318389944?pwd=c05KaHo3elQyVUo3MXQzZ2UrbDhMUT09';
const video2Url = '';

export default function Vespers() {
  return (
    <section className="section intro">
      <div className="section-wrapper">
        <div style={{ marginBottom: 120 }}>
          <h1 className="event-title">Christmas Eve (family-friendly)</h1>

          {!video1Url && (
            <a className="video-placeholder" href={call1Url}>
              <span>The family-friendly Christmas Eve service begins at 4p PST.<br />Click within the frame to join the Zoom call.</span>
            </a>
          )}

          {video1Url && (
            <Video videoEmbedUrl={video1Url} />
          )}

          <div className="video-description">
            <div className="buttons activity">
              <a className="button" href="https://mcusercontent.com/a5f3ffeb9651b7ad2bf652300/files/52a4d053-15f9-476c-9296-20d5111ffbe9/all_souls_ow_4pm_christmas_eve_2020.pdf" target="_blank">Download Liturgy PDF</a>
            </div>
          </div>
        </div>

        <div>
          <h1 className="event-title">Christmas Eve (older youth and adults)</h1>

          {!video2Url && (
            <a className="video-placeholder" href={call2Url}>
              <span>The second Christmas Eve service begins at 8p PST.<br />Click within the frame to join the Zoom call.</span>
            </a>
          )}

          {video2Url && (
            <Video videoEmbedUrl={video2Url} />
          )}

          <div className="video-description">
            <div className="buttons activity">
              <a className="button" href="https://mcusercontent.com/a5f3ffeb9651b7ad2bf652300/files/5c44b691-810b-4d2a-bdf1-82f60e431a06/all_souls_ow_christmas_eve_122420.01.pdf" target="_blank">Download Liturgy PDF</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
