import React from 'react'

import readers from '../readers.json';
import Sidebar from '../components/sidebar';

export default ({ reader }) => {
  console.log(reader, readers[reader]);
  if (!readers[reader]) {
    return null;
  }

  const { bio, name, photo } = readers[reader];

  return (
    <div className="section-wrapper">
      <Sidebar
        iconName="tree"
        title="Today's Reader"
        photo={photo && `/readers/${photo}`}
      />

      <div>
        <h1>{name}</h1>
        <p>{bio || `Thanks for reading to us, ${name.split(' ')[0]}!`}</p>
      </div>
    </div>
  );
};
