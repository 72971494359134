import React from 'react'

import Sidebar from '../components/sidebar';

export default ({ contents, iconName, title }) => (
  <div className="section-wrapper">
    <Sidebar
      iconName={iconName}
      title={title}
    />

    <div dangerouslySetInnerHTML={{__html: contents}} />
  </div>
);
