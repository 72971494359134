import CallToWorship from './sections/callToWorship';
import Connect from './sections/connect';
import Giving from './sections/giving';
import Hymn from './sections/hymn';
import Liturgy from './sections/liturgy';
import Reflection from './sections/reflection';
import Scripture from './sections/scripture';
import Welcome from './sections/welcome';

export const sectionComponents = {
  callToWorship: CallToWorship,
  connect: Connect,
  giving: Giving,
  hymn: Hymn,
  liturgy: Liturgy,
  reflection: Reflection,
  scripture: Scripture,
  welcome: Welcome,
};

const sectionTypeNames = {
  callToWorship: 'Call to Worship',
  connect: 'Connect',
  giving: 'Giving',
  hymn: 'Hymn',
  liturgy: 'Liturgy',
  reflection: 'Reflection',
  scripture: 'Scripture',
  welcome: 'Welcome',
};

const liturgyTypeNames = {
  benediction: 'Benediction',
  communitySharing: 'Community Sharing',
  confession: 'Confession',
  poem: 'Poem',
  prayersOfThePeople: 'Prayers of the People',
  receivingOfGrace: 'Receiving of Grace',
  understandingOurFaith: 'Understanding Our Faith',
};

export const getSectionName = ({ liturgyType, name, reference, type }) => {
  return name ||
    (liturgyType && liturgyTypeNames[liturgyType]) ||
    (type === 'scripture' && reference) ||
    sectionTypeNames[type];
};

const sectionLinerNotes = {
  callToWorship: 'In the Bible, God calls us to worship, because it is not our natural impulse to come to Him. Yet He calls, reminding us of His pursuing love, and we respond out of gratitude.',
  hymn: 'Music at All Souls is comprised of “psalms, hymns, and spiritual songs” (Eph. 5:19) that lend poetic voice to our deepest emotional expressions: praise and thanksgiving, anger and sorrow, hope and joy. We sing because we believe God is with us— present in our worship, both receiving and inhabiting the praises of his people.',
  scripture: 'It has been the practice of the church for thousands of years to have portions of the Bible read during worship. We continue to do this because we believe that God meets us in his Word, and that we are people who need to be shaped by this encounter.',
  confession: 'Sin is a word that in essence means to insert oneself into the space that only God can— and rightfully should— occupy as our loving Creator and Savior. Martin Luther once described sin as a “curving in on oneself,” causing a relational breach not only with the people we harm, but also with God.',
};

export const getLinerNotes = (type) => {
  return sectionLinerNotes[type] || null;
};
