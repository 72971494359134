import React from "react"

import Generic from '../sections/generic';
import Illumination from '../sections/illumination';
import Layout from '../components/layout';
import Movement from '../sections/movement';
import Reader from '../sections/reader';
import Scripture from '../sections/scripture';
import Vespers from '../sections/vespers';
import Welcome from '../sections/welcome';
import Wreath from '../sections/wreath';

const getActivityComponent = (activity) => {
  switch(activity) {
    case 'wreath':
      return <Wreath />;
    case 'vespers':
      return <Vespers />;
    case 'illumination':
      return <Illumination />;
    case 'movement':
      return <Movement />;
    default:
      return null;
  }
};

export default function Day({ pageContext }) {
  const { day, scriptures } = pageContext;

  return (
    <Layout date={day.date}>
      {day.component && getActivityComponent(day.component)}

      {day.component && (
        <section className="section tutorial">
          <div className="section-wrapper">
            <div className="title-wrapper">
              <h2>Daily Worship</h2>
            </div>
          </div>
        </section>
      )}

      <section className="section">
        <Welcome date={day.date} audioUrl={day.audioUrl} />
      </section>

      <section className="section">
        <Generic
          contents={`
            <p>
              Come, let us sing to the Lord; let us shout for joy to the Rock of our salvation.<br /><br />

              <em>Let us come before his presence with thanksgiving and raise a loud shout to him with psalms.</em><br /><br />

              For the Lord is a great God, and a great King above all gods.<br /><br />

              <em>In his hand are the caverns of the earth, and the heights of the hills are his also. The sea is his, for he made it, and his hands have molded the dry land.</em><br /><br />

              Come, let us bow down, and bend the knee, and kneel before the Lord our Maker.<br /><br />

              <em>For he is our God, and we are the people of his pasture and the sheep of his hand. Oh, that today you would hearken to his voice!</em><br /><br />
            </p>
          `}
          iconName="callToWorship"
          title="Opening Prayer"
        />
      </section>

      {day.scripture.map(scripture => (
        <section className="section" key={scripture}>
          <Scripture
            reference={scripture}
            scriptures={scriptures}
          />
        </section>
      ))}

      <section className="section">
        <Generic
          contents={`
            <p>
              Our Father, who art in heaven, Hallowed be Thy Name.<br />
              <em>Spend a moment now to be aware of God's gifts and thank Him.</em><br /><br />

              Thy kingdom come. Thy will be done, on earth as it is in heaven.<br />
              <em>What would you like to ask God to heal and restore in our world?</em><br /><br />

              Give us this day our daily bread,<br />
              <em>Talk to God about your needs and bring your worry to Him.</em><br /><br />

              And forgive us our debts<br />
              <em>Take a moment to reflect on how your actions or inactions have cost others and God.</em><br /><br />

              As we forgive our debtors.<br />
              <em>Ask God for the grace to forgive as you have been forgiven. What steps can you take toward reconciliation?</em><br /><br />

              Lead us not into temptation, but deliver us from evil,<br />
              <em>Talk to God about your fears and unburden yourself to Him. He perfectly cares for you.</em><br /><br />

              For Yours is the kingdom, and the power, and the glory forever.<br /><br />

              Amen.
            </p>
          `}
          iconName="benediction"
          title="The Lord's Prayer"
        />
      </section>

      {day.reader && (
        <section className="section reader">
          <Reader reader={day.reader} />
        </section>
      )}

      <img className="divider" src="/divider.png" />
    </Layout>
  );
};
