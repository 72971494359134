import React from "react"

import Video from '../components/video';

// Fill this in when we have a URL
const callUrl = 'https://us02web.zoom.us/j/81604415136';
const videoUrl = 'https://player.vimeo.com/video/491938887';

export default function Illumination() {
  return (
    <section className="section intro">
      <div className="section-wrapper">
        <h1 className="event-title"><b>Illumination</b> with Lori Bailey</h1>

        {!videoUrl && (
          <a className="video-placeholder" href={callUrl}>
            <span>Zoom call begins at 7p PST.<br />Click within the frame to join.</span>
          </a>
        )}

        {videoUrl && (
          <Video videoEmbedUrl={videoUrl} />
        )}

        <div className="video-description">
          <p>Explore with us as we lean into the text itself as the starting point of a conversation. What do we think scripture means when it says that the Word of God became flesh? Using the age-old art of illumination as a guide, we’ll (literally!) draw out our responses as we engage with the words of the Bible.</p>

          <p className="instructions">
            What you’ll want from your Advent kit:
            <ul>
              <li>parchment paper strips</li>
              <li>star stencil</li>
              <li>tea candles</li>
            </ul>
          </p>

          <p className="instructions">
            What you’ll need to provide:
            <ul>
              <li>any drawing utensil you prefer</li>
              <li>clear tape and/or a pair of scissors</li>
            </ul>
          </p>

          <div className="buttons activity">
            <a className="button" href="https://allsoulsseattle.s3-us-west-2.amazonaws.com/Advent+2020/Advent+2020+Embodied+Prayer.week+3.112320.pdf" target="_blank">Download Activity PDF</a>
            <a className="button" href="https://allsoulsseattle.s3-us-west-2.amazonaws.com/Advent+2020/Advent+at+All+Souls+-+ASK+reading+wk+3.pdf" target="_blank">Download Kids' Activity PDF</a>
          </div>
        </div>
      </div>
    </section>
  );
};
